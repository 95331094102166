<template>
    <div class="main-contents code">
        <div class="tit">자격증정보 관리</div>
        <div class="search-box interval">
            <SelectComp class="wd01" list="lice:자격증명,iss:발급기관명" v-model="srchFilter.indtypeClassCd" title="자격증명" @change="srchTxtPlaceholder = $event.target.selectedOptions[0].label"/>
            <InputComp v-model="srchFilter.srchTxt" :placeholder="srchTxtPlaceholder" @keyup.enter="getLicenseList('1')"/> &nbsp;
            <!-- <SelectComp class="wd01" list="01:기술사,02:기사,03:산업기사,04:기능사,05:기타" :isAll="true" title="자격증등급" v-model="srchFilter.licenseRnk"/>&nbsp; -->
            <SelectComp class="wd01" cdId="PRO116" :isAll="true" title="자격증등급" v-model="srchFilter.licenseRnk"/>&nbsp;
            <SelectComp class="wd01" v-model="srchFilter.useYn" list="Y:예,N:아니오" :isAll="true" title="사용여부"/>&nbsp;
            <img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getLicenseList('1')"/>
            <div class="sub_btn" @click="$router.push('MAN933M02')">
                + 등록
            </div>
        </div>

        <!-- 자격증정보 리스트 -->
        <div class="Board type3">
            <table class="Board_type3 admin">
                <colgroup>
                    <col width="4%">
                    <col width="8%">
                    <col width="39%">
                    <col width="25%">
                    <col width="12%">
                    <col width="12%">
                </colgroup> 
                <thead>
                    <tr>
                        <th>NO</th>
                        <th>자격증코드</th>
                        <th>자격증명</th>
                        <th>발급기관명</th>
                        <th>자격증등급</th>
                        <th>사용여부</th>
                    </tr>
                </thead>
                
                <tbody v-if="licenseInfoList.length > 0">
                <!-- 최대 25건 조회 -->
                    <tr v-for="(license, licenseIdx) in licenseInfoList" :key="licenseIdx">
                        <td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (licenseIdx+1)}}</td>
                        <td class="score">{{license.licenseCd}}</td>
                        <td class="score btn_cursor" @click="viewLicenseDetail(license)">{{license.licenseNm}}  &#9654;</td>
                        <td class="score">{{license.issOrgNm}}</td>
                        <td class="score"><SelectComp type="text" cdId="PRO116" v-model="license.licenseGradeCd"/></td>
                        <td class="score" v-if="license.useYn == 'Y'">예</td>
                        <td class="score" v-else>아니오</td>
                    </tr>

                </tbody>

                <!-- 관련내용 없을 경우 -->
                <tbody v-else>
                    <tr>
                        <td colspan="6" class="none">등록된 자격증이 없습니다!</td>
                    </tr>
                </tbody>

            </table>
            <!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
        </div>

    </div>
</template>

<script>
import SelectComp from "@/components/base/SelectComp.vue";
import pagingComp from '@/components/PagingComp.vue';

export default {
    components: { SelectComp,pagingComp },

    data() {
        return {
            // 검색 조건
            srchFilter : {
				indtypeClassCd : 'lice',
				srchTxt : '',
                licenseRnk : '',
                useYn : '',
                pageIndex : 1,

			},

            // placeholder
            srchTxtPlaceholder : '자격증명',

            // 자격증 목록
            licenseInfoList: [],

            // 페이징
            pageInfo : {}

        }
    },

    mounted() {

        var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}

        this.getLicenseList();
    },
    methods: {
        
        // 페이징 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getLicenseList();
		},

        getLicenseList(div) {
            
            var param = this.srchFilter;

            param.pageUnit = '25';
            param.pageSize = '10';
            if(div) param.pageIndex = 1;

            // console.log('getLicenseList PARAM', param);

            this.$.httpPost('/api/main/adm/license/getLicenseList', param)
                .then(res => {
                    // console.log('getLicenseList Result', res)

                    this.licenseInfoList = res.data.licenseList;
                    this.pageInfo = res.data.pageInfo;
                }).catch(this.$.httpErrorCommon);
        },

        viewLicenseDetail(lice) {
            var params = {
                liceSeq : lice.licenseCd,
                caller : {
                    name : this.$route.name,
                    params : this.srchFilter
                }
            }
            // console.log(this.$router);
			this.$router.push({name: 'MAN933M02', params });
        },

    },
}

</script>